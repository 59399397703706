import React from 'react'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import "./styles.css";
import { useMediaQuery } from '@mui/material';
import moment from 'moment-timezone';

const timerProps = {
    isPlaying: true,
    size: 50,
    strokeWidth: 6
};

const renderTime = (dimension, time) => {
    return (
        <div className="time-wrapper">
            <div className="time" style={{fontSize: '20px'}}>{time}</div>
            <div>{dimension}</div>
        </div>
    );
};

const convertToUnixTimestamp = (dateString, timeString) => {
    // Parse the date string
    const [day, month, year] = dateString.split('/').map(Number);

    // Parse the time string
    const timeRegex = /(\d+)(am|pm)/i;
    const timeMatch = timeString.match(timeRegex);
    let hours = parseInt(timeMatch[1]);
    const period = timeMatch[2].toLowerCase();

    if (period === 'pm' && hours !== 12) {
        hours += 12;
    } else if (period === 'am' && hours === 12) {
        hours = 0;
    };

    // Use moment to create a date in IST
    const dateTime = moment.tz(
        `${year}-${month}-${day} ${hours}:00:00`,
        "YYYY-MM-DD HH:mm:ss",
        "Asia/Kolkata"
    );

    // Return the time in seconds
    return dateTime.valueOf() / 1000;
};

const CountDownCircleTimer = ({date, time}) => {
    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;
    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;


    const stratTime = moment.tz("Asia/Kolkata").valueOf() / 1000; // Current time in IST as seconds
    const endTime = convertToUnixTimestamp(date, time);

    const remainingTime = endTime - stratTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isMediumScreen = useMediaQuery('(min-width:600px) and (max-width:1199px)');
    const isSmallScreen = useMediaQuery('(max-width:599px)');

    let size, gap;
    if (isLargeScreen) {
        size = 100;
        gap = '20px'
    } else if (isMediumScreen) {
        size = 90;
        gap = '10px'
    } else if (isSmallScreen) {
        size = 75;
        gap = '5px'
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', gap: gap, textAlign: 'center', paddingTop: '4px', overflow: 'hidden'}}>
            <CountdownCircleTimer
                {...timerProps}
                colors="#fff"
                trailColor="#16182F"
                strokeLinecap="square"
                duration={daysDuration}
                initialRemainingTime={remainingTime}
                size={size}
            >
                {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                        {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
                    </span>
                )}
            </CountdownCircleTimer>
            <CountdownCircleTimer
                {...timerProps}
                colors="#fff"
                trailColor="#16182F"
                duration={daySeconds}
                size={size}
                initialRemainingTime={remainingTime % daySeconds}
                onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                })}
            >
                {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                        {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                    </span>
                )}
            </CountdownCircleTimer>
            <CountdownCircleTimer
                {...timerProps}
                colors="#fff"
                trailColor="#16182F"
                duration={hourSeconds}
                size={size}
                initialRemainingTime={remainingTime % hourSeconds}
                onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                })}
            >
                {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                        {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
                    </span>
                )}
            </CountdownCircleTimer>
            <CountdownCircleTimer
                {...timerProps}
                colors="#fff"
                trailColor="#16182F"
                duration={minuteSeconds}
                size={size}
                initialRemainingTime={remainingTime % minuteSeconds}
                onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > 0
                })}
            >
                {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                        {renderTime("seconds", getTimeSeconds(elapsedTime))}
                    </span>
                )}
            </CountdownCircleTimer>
        </div>
    );
};

export default CountDownCircleTimer;
