import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import abimg from '../../images/sriram_Whats_app.jpg';


const App = () => { 

  return (
    <div className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
          <FloatingWhatsApp
            phoneNumber="+919444867208"
            accountName="Sriram"
            statusMessage="Typically replies within 15 minutes"
            avatar={abimg}
            chatMessage={`Hello there! 🤝 How can i help you?`}
            allowEsc
            allowClickAway
            notification
            notificationSound
          />
          
    </div>
  );
}

export default App;